import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useSpacing } from '../hooks'
import type { Spacing } from '../hooks'
import { Button, type ButtonProps } from '../Button'
import { Markdown } from '../Markdown'
import { isProd } from '../../utils'

const BASE_URL = isProd
  ? 'https://service-explorer.provenance.io/api'
  : 'https://service-explorer.test.provenance.io/api'

interface Stat {
  id: string
  title: string
  copy?: string
  statType: 'tvl' | 'val' | 'custom'
  bg?: `${string}-${number}00`
  color?: `${string}-${number}00`
}

export interface StatsProps {
  title: string
  label: string
  statsList: Stat[]
  content: {
    id: string
    content: string
  }[]
  button: ButtonProps[]
  spacing: Spacing[]
  style: 'primary' | 'secondary'
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  notation: 'compact',
  currency: 'USD',
  maximumFractionDigits: 0,
  maximumSignificantDigits: 2,
})

export const Stats = ({ data }: { data: StatsProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)

  const Copy = ({ stat }: { stat: Stat }) => {
    const [copy, setCopy] = useState(stat.copy)

    useEffect(() => {
      const getValidators = async () => {
        try {
          const res = await fetch(
            `${BASE_URL}/v3/validators/recent?count=1&status=ACTIVE`,
          )
          const data = await res.json()
          setCopy(data.total)
        } catch (e) {
          console.error(e)
        }
      }

      if (stat.statType === 'val') getValidators()
    }, [stat.statType])

    useEffect(() => {
      const getTvl = async () => {
        try {
          const res = await fetch(`${BASE_URL}/v2/spotlight`)
          const data = await res.json()

          setCopy(formatter.format(data.totalAum.amount))
        } catch (e) {
          console.error(e)
        }
      }

      if (stat.statType === 'tvl') getTvl()
    }, [stat.statType])

    return (
      <p
        className={clsx(
          'font-MaisonNeueExtended  font-semibold tracking-wide',
          {
            [`text-white`]: !stat.color,
            [`text-${stat.color}`]: stat.color,
            'text-3xl': data.style === 'primary',
            'text-[5.625rem]': data.style === 'secondary',
          },
        )}
      >
        {copy}
      </p>
    )
  }

  return (
    <div
      className={clsx(
        'max-w-page px-4 md:mx-auto md:px-16 lg:px-20 xl:px-24',
        spacing,
      )}
    >
      <div className="text-sm font-semibold text-blue-700">{data.label}</div>

      <div className="mb-16 mt-2 font-MaisonNeueExtended text-3xl font-bold text-black md:text-5xl">
        {data.title}
      </div>

      <div
        className={clsx('grid grid-cols-1', {
          'gap-8 lg:grid-cols-3': data.style === 'primary',
          'gap-12 xl:grid-cols-2 xl:gap-8': data.style === 'secondary',
        })}
      >
        {data.statsList.map(stat => (
          <div
            key={stat.id}
            className={clsx({
              [`bg-blue-600`]: !stat.bg,
              [`bg-${stat.bg}`]: stat.bg,
              'rounded-3xl px-14 py-12': data.style === 'primary',
            })}
          >
            <header
              className={clsx('mb-4 text-xl', {
                [`text-gray-300`]: !stat.color,
                [`text-${stat.color}`]: stat.color,
              })}
            >
              {stat.title}
            </header>
            <Copy stat={stat} />
          </div>
        ))}
      </div>

      {data.content.length > 0 && (
        <div
          className={clsx(`mb-4 font-normal text-gray-700`, {
            'mt-8 text-lg': data.style === 'primary',
            'mt-16 text-2xl': data.style === 'secondary',
          })}
        >
          {data.content.map((item, index) => (
            <div key={item.id} className={clsx({ 'pt-2': index !== 0 })}>
              <Markdown markdown={item.content} />
            </div>
          ))}
        </div>
      )}

      {data?.button?.length > 0 &&
        data.button.map((button, index) => (
          <Button
            key={button.label}
            linkTo={button.linkTo}
            btnType={button.btnType}
            size={button.size}
            className={`${index > 0 && 'ml-4'}`}
          >
            {button.label}
          </Button>
        ))}
    </div>
  )
}
