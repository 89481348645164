import React, { useState } from 'react'
import clsx from 'clsx'
import type { ButtonProps } from '../Button'
import { ButtonGroup } from '../TextAndContent/ButtonGroup'
import { Lottie } from './Lottie'
import { ThreeJs } from './ThreeJs'
import { Card } from '../ui/card'
import { Input, Message } from '../FormComponent/Components'
import { Button } from '../Button/Button'
import { useForm, Controller } from 'react-hook-form'
import { getCookie } from '../../utils'

export type HeaderDataProps = {
  id: string
  anchorTag: string
  bgGradientStart?: string
  bgGradientEnd?: string
  buttons: ButtonProps[]
  direction: 'horizontal' | 'vertical'
  image?: {
    url: string
    alt?: string
  }
  lottieFile: string
  title?: string
  subtitle?: string
  textGradientStart?: string
  textGradientEnd?: string
  threeJs?: string
  signupHeader?: boolean
}

const SignupForm = () => {
  const [status, setStatus] = useState('')
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const formId = '2fa37042-f48a-425a-b627-dcaf5a6661bb'

  const onSubmit = async (data: any) => {
    setStatus('pending')
    /* const ipRes = await fetch('https://jsonip.com/')
     const { ip: ipAddress } = await ipRes.json() */
    const hutk = getCookie('hubspotutk')
    const loc = location?.href

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/48233040/${formId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: Object.keys(data).map(key => ({
            name: key,
            value: data[key],
          })),
          context: {
            hutk,
            pageUri: loc,
            // Return page name as the last index of the url
            pageName: loc.substring(loc.lastIndexOf('/')),
            /* ipAddress, */
          },
        }),
      },
    )
    if (res.ok && res.status < 400) {
      setStatus('completed')
      reset()
    } else setStatus('failed')
  }

  const pending = status === 'pending'
  const completed = status === 'completed'
  const failed = status === 'failed'

  return (
    <div className="relative mx-auto flex h-auto w-full max-w-md">
      {/* Animated gradient background with blur */}
      <Card className="relative z-20 my-auto flex h-min w-full flex-col gap-8 rounded-[6px] bg-white p-6 lg:relative">
        <div
          style={{ filter: 'blur(40px)' }}
          className="absolute inset-0 z-[-2] overflow-hidden rounded-3xl"
        >
          <div
            className="absolute inset-[-50%] h-[200%] w-[200%]"
            style={{
              maskImage:
                'https://www.datocms-assets.com/114273/1741651824-vector.svg',
              background: `radial-gradient(circle at 50% 50%, 
              rgba(255, 0, 128, 0.5), 
              rgba(64, 93, 230, 0.5), 
              rgba(88, 189, 134, 0.5))`,
              animation: 'moveGradient 15s alternate infinite ease-in-out',
            }}
          />
        </div>
        <div className="absolute inset-0 z-[-1] rounded-[6px] bg-white"></div>
        {completed ? (
          <div className="flex flex-col gap-4">
            <Message
              message="Thanks for submitting your email address.   We'll be back shortly with an update on your access to ProvConnect."
              success
            />
            <Message message="Best, ProvLabs team" success />
          </div>
        ) : (
          <div className="my-auto flex flex-col gap-4">
            <h1 className="font-regular text-lg text-gray-700">
              Request early access to ProvConnect
            </h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              id={formId}
              className="flex w-full items-center gap-2"
            >
              <Input
                register={register}
                name="email"
                type="email"
                required
                label="Email"
                className="flex h-[38px] w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                placeholder="Email"
                errors={errors}
              />
              <Button
                className="rounded-[6px] font-medium before:rounded-[6px]"
                btnType="light"
                size="small"
                type="submit"
                disabled={pending}
              >
                Get Early Access
              </Button>
            </form>
            <p className="font-MaisonNeue text-sm text-gray-600">
              Your gateway to tokenizing assets, building and integrating
              applications, viewing your assets and Provenance Blockchain
              activity, and more.
            </p>
          </div>
        )}
        {failed && <Message failure />}
      </Card>
      {/* CSS Animation */}
      <style>{`
        @keyframes moveGradient {
          0% {
            transform: translate(0%, 0%) rotate(0deg);
          }
          25% {
            transform: translate(-15%, 10%) rotate(45deg);
          }
          50% {
            transform: translate(10%, -10%) rotate(90deg);
          }
          75% {
            transform: translate(-5%, 15%) rotate(45deg);
          }
          100% {
            transform: translate(5%, -5%) rotate(0deg);
          }
        }
      `}</style>
    </div>
  )
}

export const Header = ({ data }: { data: HeaderDataProps[] }) => {
  return data.map(item => {
    const isHorizontal = item.direction === 'horizontal'
    const isVertical = item.direction === 'vertical'
    return (
      <div
        key={item.id}
        className={`
          bg-gradient-to-br from-5% to-95%
          from-${item.bgGradientStart || 'gray-300'}
          to-${item.bgGradientEnd || 'white'}
        `}
      >
        <div
          key={item.id}
          className={clsx('mt-28 pt-16 md:pt-24', {
            'grid grid-cols-1 place-items-center': !item.signupHeader,
            'flex flex-col gap-8 min-[1043px]:grid min-[1043px]:grid-cols-[55%_1fr] ':
              item.signupHeader,
            'max-w-page gap-x-0 px-4 sm:grid-cols-2 md:mx-auto md:grid-cols-[55%_1fr] md:gap-x-12 md:px-16 lg:px-20 xl:gap-x-32 xl:px-24':
              isHorizontal,
            'gap-14 text-center': isVertical,
            'pb-8 md:pb-[4.5rem] lg:pb-32':
              (isVertical && !item.image?.url) || isHorizontal,
          })}
        >
          <div
            className={clsx({
              'col-span-2 flex h-min flex-col gap-8 md:col-span-1':
                isHorizontal,
              'max-w-page px-4 md:mx-auto md:px-16 lg:px-20 xl:px-24':
                isVertical,
            })}
          >
            <div className="flex flex-col gap-2">
              <h1
                className={clsx(
                  `inline-block bg-gradient-to-br from-15% to-70%`,
                  `bg-clip-text font-MaisonNeueExtended font-bold text-transparent xl:leading-snug`,
                  `from-${item.textGradientStart || 'blue-600'} to-${item.textGradientEnd || 'blue-500'}`,
                  {
                    'text-5xl xl:text-[4.575rem]': isHorizontal,
                    'text-4xl md:text-7xl xl:text-[4.625rem]': isVertical,
                  },
                )}
              >
                {item.title}
              </h1>

              {item.subtitle && (
                <h2
                  className={clsx('text-gray-900', {
                    'mt-4 text-lg xl:text-xl': isHorizontal,
                    'md:my-10': !item.signupHeader,
                    'lg:my-12': item.signupHeader,
                    'mt-8 text-lg md:text-xl xl:mt-16 xl:text-3xl': isVertical,
                  })}
                >
                  {item.subtitle}
                </h2>
              )}
            </div>
            {item.buttons.length > 0 && (
              <ButtonGroup
                buttons={item.buttons}
                className={clsx({
                  'relative z-10 justify-self-start sm:static md:col-start-1 md:row-start-2':
                    isHorizontal,
                  'mx-auto my-6 justify-center md:my-0': isVertical,
                })}
              />
            )}
          </div>
          {item.signupHeader && item.threeJs ? (
            <div className="flex">
              <SignupForm />
              <ThreeJs threeJsScript={item.threeJs} />
            </div>
          ) : (
            ''
          )}
          {!item.signupHeader &&
            (item.lottieFile || item.image?.url || item.threeJs) && (
              <div
                className={clsx({
                  'max-h-56 max-w-56 justify-self-end md:row-span-2 md:max-h-none md:max-w-none md:justify-self-auto':
                    isHorizontal,
                })}
              >
                {item.threeJs ? (
                  <ThreeJs threeJsScript={item.threeJs} />
                ) : item.lottieFile && isHorizontal ? (
                  <Lottie lottieFile={item.lottieFile} />
                ) : (
                  <img
                    src={item.image?.url}
                    alt={item.image?.alt}
                    className={clsx({
                      'max-h-56 max-w-56 md:max-h-none md:max-w-none':
                        isHorizontal,
                    })}
                  />
                )}
              </div>
            )}
        </div>
      </div>
    )
  })
}
