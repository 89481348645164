import React from 'react'
import { Markdown } from '../Markdown'
import { GatedContentProps } from './GatedContentComponent'

export const WhitePaper = ({ data }: { data: GatedContentProps }) => {
  return (
    <div className="fixed z-0 flex aspect-[8.5/11] h-auto w-[300px] flex-col gap-0 rounded-sm border border-gray-400 bg-white opacity-50 shadow-lg md:relative md:w-[400px] md:min-w-[360px] md:opacity-100">
      {/* Upper Area */}
      <div className="mx-6 mb-8 mt-6 flex h-1/2 flex-col justify-between justify-items-start bg-white">
        <img
          src="/ProvLabsLogo.svg"
          alt="ProvLabs Logo"
          className="h-5 w-min"
        />
        <div className="flex flex-col gap-2">
          <p className="text-base font-extralight tracking-tight text-gray-900 sm:text-lg md:text-xl">
            {data.whitepaperTitle}
          </p>
          <div className="text-xs text-gray-600 sm:text-sm">
            {data.whitepaperContent?.map(blurb => (
              <Markdown key={blurb.id} markdown={blurb.content} />
            ))}
          </div>
        </div>
      </div>
      {/* Lower Half */}
      <div className="aspect-video w-full">
        <img className="w-full object-cover" src={data.image.url || ''} />
      </div>
    </div>
  )
}
