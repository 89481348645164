import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import type { PageProps } from 'gatsby'
import { useSpacing, type Spacing } from '../hooks'
import { Grid } from '../Grid'
import { BlogPostCard } from './BlogPostCard'
import { getArticle } from './BlogList'
import { PaginationClicker } from '../Quotations/Components/PaginationClicker'

export interface BlogPostPickerProps {
  data: Queries.DatoCmsBlogPostPicker
  location: PageProps['location']
}

export const BlogPostPicker = ({ data, location }: BlogPostPickerProps) => {
  const amt = data.posts?.length || 0
  const pageSize = amt <= 2 ? 1 : 3

  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const [pages, setPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setPages(Math.ceil(amt / pageSize))
  }, [amt, pageSize])

  return data.posts ? (
    <div
      className={clsx(
        'max-w-page px-4 md:mx-auto md:px-16 lg:px-20 xl:px-24',
        spacing,
      )}
    >
      <Grid {...(data.layout?.[0] as any)}>
        {data.posts
          ?.slice(pageSize * (currentPage - 1), pageSize * currentPage)
          .map(post => (
            <BlogPostCard
              key={post?.id}
              article={getArticle(post as any)}
              widthFull={amt <= 2}
              showImage={!!data.showImages || amt <= 2}
            />
          ))}
        {pages > 1 && (
          <div className="col-span-full">
            <PaginationClicker
              currentNumber={currentPage}
              setCurrentNumber={setCurrentPage}
              totalItems={pages}
            />
          </div>
        )}
      </Grid>
    </div>
  ) : null
}
