import { data } from 'cypress/types/jquery'
import { Button } from '../Button'
import { Card, CardContent, CardHeader } from '../ui/card'
import { Check } from 'lucide-react'
import React from 'react'

export interface PricingTableProps {
  queryapi: boolean
}

export const PricingTable = ({ data }: { data: PricingTableProps }) => {
  // Basic plan features data
  const basicFeatures = [
    'Quick Start to define integration and Web 3 requirements.',
    'Rapid deployment',
    'Essential feature set',
    'Basic customization options',
    'Standard support and maintenance',
  ]

  // Advanced plan features data
  const advancedFeatures = [
    'Everything in Basic',
    {
      special: true,
      text: 'ProvLabs Professional Services',
    },
    'Custom implementation',
    'Advanced integration',
    'Tailored workflows',
    'Premium strategic support and maintenance',
  ]

  return data.queryapi ? (
    <Card className="mx-4 max-w-[634px] overflow-hidden rounded-lg bg-transparent [background:linear-gradient(94deg,rgba(8,19,43,1)_0%,rgba(36,70,127,1)_100%)] sm:mx-auto">
      <CardContent className="flex flex-col gap-6 p-8">
        {/* GET STARTED heading with gradient */}
        <div className="bg-gradient-to-br from-[#DEE897] to-[#CCDE44] bg-clip-text text-[20px] font-medium leading-5 text-transparent">
          GET STARTED
        </div>

        {/* Price display */}
        <div className="flex items-start">
          <span className="text-2xl text-gray-500">$</span>
          <span className="font-MaisonNeueExtended text-5xl font-bold leading-[48px] text-white">
            2,999
          </span>
          <span className="mb-1 self-end text-2xl text-gray-500">/yr</span>
        </div>

        {/* CTA Button */}
        <div className="w-full">
          <Button
            btnType="light"
            className="font-button-text-provlabs-medium h-auto w-full rounded-[200px] border-gray-700 py-3 text-black"
            linkTo="/ProvConnect-Query-API-Signup-1/"
          >
            Create an Account
          </Button>
        </div>

        {/* Feature heading */}
        <div className="text-2xl font-semibold leading-[31.2px] text-white [font-family:'Maison_Neue-SemiBold',Helvetica]">
          Up to 2 Concurrent Queries
        </div>

        {/* Feature description */}
        <div className="text-sm font-normal italic text-gray-400">
          100 small queries (run time of ≤ 10ms) per second, or 2 queries (run
          time of ≤ 30 seconds) per minute.
        </div>
      </CardContent>
    </Card>
  ) : (
    <div className="mx-auto flex max-w-page flex-col items-center gap-6 px-4 md:flex-row md:gap-0 md:px-16 lg:px-20 xl:px-24">
      {/* Basic Plan Card */}
      <Card className="border-zinc-200 shadow-shadow-base flex w-full flex-1 flex-col items-start gap-6 rounded-xl bg-[color:var(--3-mode-base-card)] p-6 py-8 md:w-auto">
        <div className="self-stretch bg-gradient-to-br from-blue-700 to-[#3C70C7] bg-clip-text text-[20px] font-medium leading-5 text-transparent">
          BASIC
        </div>

        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex w-full items-start gap-0.5">
            <h2 className="text-5xl font-bold leading-[48px] text-black">
              1 Year
            </h2>
          </div>

          <p className="text-base leading-6 text-gray-700">
            Deploy fast and manage seamlessly, starting with our preconfigured
            capabilities.
          </p>
        </div>

        <div className="w-full">
          <Button
            btnType="outline"
            className="font-button-text-provlabs-medium h-auto w-full rounded-[200px] border-gray-700 py-3 text-gray-700"
            linkTo="/services/managed/AssetManager/#contact-us"
          >
            Get Started
          </Button>
        </div>

        <div className="flex w-full flex-col items-start gap-4">
          <h3 className="text-lg font-semibold text-black">
            What&apos;s Included:
          </h3>

          <div className="flex w-full flex-col items-start gap-2">
            {basicFeatures.map((feature, index) => (
              <div key={index} className="flex w-full items-start gap-1.5">
                <div className="flex h-7 w-[21px] items-center justify-center">
                  <Check className="h-6 w-6 text-gray-700" />
                </div>
                <p className="text-lg font-normal leading-7 text-gray-700">
                  {feature}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Card>

      {/* Advanced Plan Card */}
      <Card className="flex flex-1 flex-col items-start gap-6 rounded-xl border-none p-6 py-8 shadow-[0px_10px_10px_-5px_#00000080,0px_20px_25px_-5px_#0000001a] [background:linear-gradient(94deg,rgba(8,19,43,1)_0%,rgba(36,70,127,1)_100%)]">
        <div className="bg-gradient-to-br from-[#DEE897] to-[#CCDE44] bg-clip-text text-[20px] font-medium leading-5 text-transparent">
          ADVANCED
        </div>
        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex w-full items-start gap-0.5">
            <h2 className="text-5xl font-bold leading-[48px] text-white">
              1 Year
            </h2>
          </div>

          <p className="text-base leading-6 text-gray-400">
            Leverage our expertise to customize your integration with
            super-charged capabilities.
          </p>
        </div>

        <div className="w-full">
          <Button
            btnType="light"
            className="h-auto w-full rounded-[200px] py-3"
            linkTo="/services/managed/AssetManager/#contact-us"
          >
            Get Started
          </Button>
        </div>

        <div className="flex w-full flex-col items-start gap-4">
          <h3 className="text-lg font-semibold text-white">
            What&apos;s Included:
          </h3>

          <div className="flex w-full flex-col items-start gap-2">
            {advancedFeatures.map((feature, index) => (
              <div
                key={index}
                className={`flex w-full items-start gap-1.5 ${typeof feature !== 'string' && feature.special ? '' : ''}`}
              >
                {typeof feature === 'string' ? (
                  <>
                    <div
                      className={`flex h-7 w-[21px] items-center justify-center ${index > 1 ? 'ml-3.5' : ''}`}
                    >
                      <Check className="h-6 w-6 text-white" />
                    </div>
                    <p className="text-lg font-normal leading-7 text-gray-400">
                      {feature}
                    </p>
                  </>
                ) : (
                  <>
                    <img
                      src="https://www.datocms-assets.com/114273/1727250139-provlabsfooter.svg"
                      className="my-auto h-6 w-6 text-white"
                    />
                    <div className="flex-1">
                      <p className="text-lg font-bold leading-7 text-white">
                        {feature.text}
                      </p>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  )
}
