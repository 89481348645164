import React from 'react'
import { ImageType } from '../../templates/Generic'
import { Spacing, TextSizeType, TextComponentProps, type ButtonProps } from '..'
import { NewFeaturesDefault } from './NewFeaturesDefault'
import { NewFeaturesFourColumn } from './FourColumnsLayout'

export interface NewFeaturesProps {
  spacing: Spacing[]
  fourColumnStyle: boolean
  titleSection: TextComponentProps[]
  title: string
  image: ImageType
  cardList: {
    title: string
    content: string
    contentBlurb: {
      id: string
      content: string
    }[]
    cardImage?: ImageType
    text: TextSizeType
    button: ButtonProps[]
  }[]
}

export const NewFeatures = ({ data }: { data: NewFeaturesProps }) => {
  if (data.fourColumnStyle === (false || null)) {
    return <NewFeaturesDefault data={data} />
  } else {
    return <NewFeaturesFourColumn data={data} />
  }
}
