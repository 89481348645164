import { motion, HTMLMotionProps } from 'motion/react'
import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from '@/lib/utils'

// Animation variants for common animations
export const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: (delay = 0) => ({
    opacity: 1,
    transition: { duration: 0.5, delay },
  }),
}

export const slideInVariants = {
  hidden: (direction = 'left') => ({
    opacity: 0,
    x: direction === 'left' ? -20 : direction === 'right' ? 20 : 0,
    y: direction === 'up' ? 20 : direction === 'down' ? -20 : 0,
  }),
  visible: (delay = 0) => ({
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.6,
      delay,
      ease: [0.22, 1, 0.36, 1],
    },
  }),
}

export const staggerChildrenVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

export const scaleVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: (delay = 0) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delay,
    },
  }),
}

// Reusable motion components
export const MotionDiv = motion.div
export const MotionSection = motion.section
export const MotionSpan = motion.span
export const MotionButton = motion.button

// Animated container with variants
interface AnimatedContainerProps {
  children: React.ReactNode
  variant?:
    | 'fade'
    | 'slideLeft'
    | 'slideRight'
    | 'slideUp'
    | 'slideDown'
    | 'scale'
    | 'stagger'
  delay?: number
  className?: string
  as?: 'div' | 'section' | 'article' | 'aside'
  viewport?: boolean
}

export const AnimatedContainer = ({
  children,
  variant = 'fade',
  delay = 0,
  className,
  as = 'div',
  viewport = true,
  ...props
}: AnimatedContainerProps) => {
  const Component =
    as === 'div' ? MotionDiv : as === 'section' ? MotionSection : MotionDiv

  let variants
  let custom = delay
  let direction

  switch (variant) {
    case 'fade':
      variants = fadeInVariants
      break
    case 'slideLeft':
      variants = slideInVariants
      direction = 'left'
      break
    case 'slideRight':
      variants = slideInVariants
      direction = 'right'
      break
    case 'slideUp':
      variants = slideInVariants
      direction = 'up'
      break
    case 'slideDown':
      variants = slideInVariants
      direction = 'down'
      break
    case 'scale':
      variants = scaleVariants
      break
    case 'stagger':
      variants = staggerChildrenVariants
      break
    default:
      variants = fadeInVariants
  }

  const motionProps = {
    className: cn(className),
    initial: 'hidden',
    animate: 'visible',
    custom: custom || direction,
    variants,
    ...(viewport ? { viewport: { once: true, margin: '-100px' } } : {}),
  }

  return <Component {...motionProps}>{children}</Component>
}

// Micro-interaction animations
export const microInteractions = {
  hover: {
    scale: 1.02,
    transition: { duration: 0.2 },
  },
  tap: {
    scale: 0.98,
    transition: { duration: 0.1 },
  },
  pulse: {
    scale: [1, 1.05, 1],
    transition: { duration: 0.5, times: [0, 0.5, 1] },
  },
}

// Animated item for staggered lists
export const AnimatedItem = ({
  children,
  className,
  delay = 0,
}: {
  children: React.ReactNode
  className?: string
  delay?: number
}) => (
  <MotionDiv
    className={className}
    variants={{
      hidden: { opacity: 0, y: 10 },
      visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.4, delay },
      },
    }}
  >
    {children}
  </MotionDiv>
)
