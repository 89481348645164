import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { SmartLink } from '..'
import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'relative z-[1] flex items-center justify-center whitespace-nowrap border border-solid text-center before:absolute before:left-0 before:top-0 before:-z-[1] before:h-full before:w-full before:opacity-0 before:transition-opacity before:duration-300 hover:before:opacity-100',
  {
    variants: {
      btnType: {
        primary:
          'border-transparent bg-gradient-to-br from-blue-700 to-[#3C70C7] text-gray-100 before:bg-gradient-to-br before:from-blue-900 before:to-[#24467F]',
        light:
          'border-transparent bg-gradient-to-br from-[#DEE897] to-[#CCDE44] text-gray-900 before:bg-gradient-to-br before:from-[#CBDA58] before:to-[#A5B620]',
        outline: 'border-gray-700 bg-transparent text-gray-700',
        icon: 'rounded-[200px] border-gray-700',
      },
      size: {
        small: 'text-sm',
        medium: 'text-base',
        large: 'text-xl',
        full: 'w-full text-xl',
      },
      font: {
        medium: 'font-medium',
        bold: 'font-bold',
        semibold: 'font-semibold',
      },
      rounded: {
        full: 'rounded-full before:rounded-full',
        md: 'rounded-md before:rounded-md',
        none: 'rounded-none before:rounded-none',
      },
    },
    compoundVariants: [
      {
        size: 'small',
        btnType: ['primary', 'light', 'outline'],
        class: 'px-4 py-2',
      },
      {
        size: 'medium',
        btnType: ['primary', 'light', 'outline'],
        class: 'px-8 py-3',
      },
      {
        size: 'large',
        btnType: ['primary', 'light', 'outline'],
        class: 'px-12 py-5',
      },
      {
        size: 'full',
        btnType: ['primary', 'light', 'outline'],
        class: 'px-12 py-5',
      },
      {
        size: 'small',
        btnType: 'icon',
        class: 'p-2',
      },
      {
        size: 'medium',
        btnType: 'icon',
        class: 'p-3',
      },
      {
        size: 'large',
        btnType: 'icon',
        class: 'p-4',
      },
    ],
    defaultVariants: {
      btnType: 'primary',
      size: 'medium',
      font: 'medium',
      rounded: 'full',
    },
  },
)

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    id?: string
    children: React.ReactNode
    className?: string
    linkTo?: string
    label?: string
  }

/** Creates a button on the page */
export const Button = ({
  id,
  children,
  className = '',
  linkTo = '',
  btnType,
  size,
  font,
  rounded,
  ...rest
}: ButtonProps) => (
  <SmartLink linkTo={linkTo}>
    <button
      key={id}
      id={id}
      {...rest}
      className={cn(
        buttonVariants({ btnType, size, font, rounded, className }),
      )}
    >
      {children}
    </button>
  </SmartLink>
)
