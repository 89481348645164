import { ImageType } from '@/templates/Generic'
import clsx from 'clsx'
import { useSpacing, Spacing } from '../hooks'
import { Markdown } from '../Markdown'
import { TextComponent } from '../TextComponent'
import {
  CarouselItem,
  Card,
  Carousel,
  CarouselDots,
  CarouselContent,
} from '../ui'
import { StepsProps } from './StepsComponent'
import React from 'react'

/* New Steps Layout */
const BuildSlidingStep = ({
  step,
  index,
  stepBackground,
}: {
  step: StepsProps['stepList'][0]
  index: number
  stepBackground: ImageType
}) => (
  <CarouselItem className="h-auto basis-[97%] pl-4 md:basis-1/2">
    <Card className="flex h-full flex-col gap-2 rounded-2xl border border-yellow-400 bg-blue-900 px-8 py-8 md:px-12">
      {/* Title Row */}
      <div className="flex flex-row gap-2">
        {step.stepIcon?.url && (
          <img
            className="mt-0.5 size-6"
            src={step.stepIcon?.url}
            alt={
              step.stepIcon?.alt ||
              step.stepIcon?.filename ||
              `${step.title} icon`
            }
          />
        )}
        <h4 className="my-auto text-[20px] text-white">{step.title}</h4>
      </div>
      {/* Steps Holder */}
      <div>
        <div className="text-base text-gray-400 lg:text-lg">
          {step.contentBlurb.map(blurb => (
            <Markdown key={blurb.id} markdown={blurb.content} />
          ))}
        </div>
      </div>
    </Card>
  </CarouselItem>
)

export const SlidingSteps = ({ data }: { data: StepsProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div
      className={clsx(
        spacing,
        'mx-auto max-w-page px-4 md:px-16 lg:px-20 xl:px-24',
      )}
    >
      <TextComponent data={data.titleSection} />
      <div
        className={clsx(
          'flex h-min flex-col overflow-hidden rounded-2xl bg-gradient-to-br from-blue-900 to-[#24467F]',
        )}
      >
        {/* How It Works */}
        <div className="bg-[#09132C] px-8 py-5 md:px-12">
          <h3 className="font-MaisonNeueExtended text-lg font-medium text-white">
            {data.title ? data.title : 'How It Works'}
          </h3>
        </div>
        {/* Steps Holder */}
        <div className="mb-4 flex flex-col pt-4 md:mb-12">
          <Carousel
            opts={{
              align: 'start',
              loop: false,
              dragFree: false,
            }}
            className="no-scrollbar overflow-x-scroll px-2 py-2 md:px-4"
          >
            <CarouselDots className="sticky left-0 mb-6" />
            <CarouselContent className="flex gap-1">
              {data.stepList.map((step, index) => (
                <BuildSlidingStep
                  key={index}
                  step={step}
                  index={index}
                  stepBackground={data.stepBackground}
                />
              ))}
            </CarouselContent>
          </Carousel>
        </div>
      </div>
    </div>
  )
}
