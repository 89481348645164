import clsx from 'clsx'
import { useSpacing, Spacing } from '../hooks'
import { ButtonGroup } from '../TextAndContent/ButtonGroup'
import { CTABarComponentProps } from './CTABarComponent'
import React from 'react'

const SpecialCase = () => {
  return (
    <div className="mx-auto mb-2 flex max-w-[690px] flex-col gap-2 md:flex-row md:gap-4">
      <h2 className="text-center text-5xl font-medium text-white">
        Empowering
      </h2>
      <img
        src="https://www.datocms-assets.com/114273/1741678793-bridgetowerlogo.svg"
        className="my-auto h-11"
        alt="Bridgetower Logo"
      />
    </div>
  )
}

export const CTABox = ({ data }: { data: CTABarComponentProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div className={clsx(spacing, 'mx-auto max-w-page')}>
      <div
        className="mx-4 flex flex-col gap-8 overflow-hidden rounded-2xl px-6 py-16 sm:mx-16 sm:px-16 sm:py-16 md:py-32 lg:mx-20 xl:mx-24 xl:py-24"
        style={{
          background: `${data.image?.url ? `url(${data.image?.url as string})` : `linear-gradient(135deg, rgba(6,41,123,1) 0%, rgba(1,5,15,1) 25%, rgba(0,0,0,1) 50%, rgba(24,28,2,1) 75%, rgba(134,153,11,1) 100%)`}`,
          backgroundSize: '100% 100%',
        }}
      >
        <div
          className={clsx(
            'mx-auto flex max-w-[690px] flex-col justify-center gap-4',
            {
              'lg:flex-row lg:gap-16': data.gatedContent,
            },
          )}
        >
          {data.title === 'Empowering Bridgetower' ? (
            <SpecialCase />
          ) : (
            <h2
              className={clsx('text-center text-5xl font-semibold', {
                'lg:text-left': data.gatedContent,
                'text-gray-900': !data.darkMode,
                'text-white': data.darkMode,
              })}
            >
              {data.title}
            </h2>
          )}
          <p
            className={clsx('text-center text-base font-normal', {
              'lg:text-left': data.gatedContent,
              'text-gray-700': !data.darkMode,
              'text-white': data.darkMode,
            })}
          >
            {data.subtext}
          </p>
        </div>
        <ButtonGroup buttons={data.button} className="justify-center" />
      </div>
    </div>
  )
}
