import clsx from 'clsx'
import React, { useState } from 'react'
import {
  breakpoints,
  Button,
  type ButtonProps,
  Spacing,
  TextComponent,
  useMediaQuery,
  useSpacing,
} from '..'
import { ImageType } from '../../templates/Generic'
import { TextComponentProps } from '..'
import { Sublink } from '../Sublink'

export interface FaqProps {
  spacing: Spacing[]
  imageFirst: boolean
  titleSection: TextComponentProps[]
  faqList: {
    id: string
    title: string
    copy: string
    ctaText: string
    linkto: string
    image: ImageType
  }[]
  button: ButtonProps[]
}

const TextItems = ({
  data,
  selected,
  setSelected,
}: {
  data: FaqProps
  selected: number
  setSelected: React.Dispatch<React.SetStateAction<number>>
}) => (
  <div className="flex flex-col justify-between">
    {data.titleSection.length > 0 && <TextComponent data={data.titleSection} />}
    {data.faqList.map((faq, index) => (
      <div
        key={faq.id}
        onClick={() => {
          setSelected(index)
        }}
        className={clsx(`cursor-pointer px-4 py-8 transition ease-in`, {
          'rounded-[20px] bg-gray-300': selected === index,
        })}
      >
        <div
          className={clsx(`text-xl font-bold`, {
            'text-black': selected === index,
            'text-gray-600': selected !== index,
          })}
        >
          {faq.title}
        </div>
        <div
          className={clsx({
            block: selected === index,
            hidden: selected !== index,
          })}
        >
          <div className="py-2 text-base text-gray-700">{faq.copy}</div>
          <Sublink
            title={faq.ctaText}
            linkTo={faq.linkto}
            className="font-MaisonNeueExtended underline"
          />
        </div>
      </div>
    ))}
    {data.button.length > 0 && (
      <Button
        className="mt-4"
        linkTo={data.button[0].linkTo}
        btnType={data.button[0].btnType}
        size={data.button[0].size}
      >
        {data.button[0].label}
      </Button>
    )}
  </div>
)

const Image = ({ data, selected }: { data: FaqProps; selected: number }) => (
  <img
    src={data.faqList[selected].image.url as string}
    alt={
      data.faqList[selected].image.alt ||
      data.faqList[selected].image.filename ||
      `${data.faqList[selected].title} icon`
    }
  />
)

export const Faq = ({ data }: { data: FaqProps }) => {
  const [selected, setSelected] = useState(0)
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('md'))
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div
      className={clsx(
        'grid max-w-page grid-cols-1 items-center gap-8 px-4 py-12 md:mx-auto md:grid-cols-2 md:px-16 lg:px-20 xl:px-24',
        spacing,
      )}
    >
      {data.imageFirst && !renderMobile ? (
        <>
          <Image data={data} selected={selected} />
          <TextItems
            data={data}
            selected={selected}
            setSelected={setSelected}
          />
        </>
      ) : (
        <>
          <TextItems
            data={data}
            selected={selected}
            setSelected={setSelected}
          />
          <Image data={data} selected={selected} />
        </>
      )}
    </div>
  )
}
