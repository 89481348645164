import React from 'react'
import { Button, Footer, Header, HeaderDataProps, TopBar } from '..'
import { ChevronLeftIcon } from 'lucide-react'

export const MinimalPageChrome = ({
  header,
  className,
  children,
}: {
  header?: HeaderDataProps[]
  className?: string
  children: any
}) => {
  const hasHeader = !!header?.find((data: any) => data.title)

  return (
    <div
      className="flex min-h-screen w-screen flex-col items-center justify-center gap-8 
    bg-gradient-to-br from-gray-300 from-5%
    to-white
    to-95% px-4 md:px-12 lg:px-16 xl:px-24"
    >
      <div className="max-w-page">
        {/* Absolutely positioned back or ProvLabs Icon */}
        <button
          onClick={() => {
            const currentPath = window.location.pathname
            if (currentPath.startsWith('/ProvConnect-Query-API-Signup')) {
              window.location.href = '/services/managed/ProvConnect/Query-APIs/'
              return
            }
            
            if (window.history.length > 1) {
              window.history.back()
            } else {
              setTimeout(() => {
                window.location.href = '/'
              }, 100)
            }
          }}
          className="fixed top-4 left-4 transition-transform hover:scale-105 sm:left-8 sm:top-8 md:left-12 md:top-12"
          aria-label="Go back"
        >
          {window.location.pathname.startsWith('/ProvConnect-Query-API-Signup') || window.history.length > 1 ? (
            <Button btnType="outline" size="small">
              <ChevronLeftIcon className="size-4" /> Back
            </Button>
          ) : (
            <img
              src="/icon.svg"
              alt="ProvLabs Logomark"
              className="size-10 sm:size-14"
            />
          )}
        </button>

        {/* Page Content */}
        <main>{children}</main>
      </div>
    </div>
  )
}
