import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form'
import clsx from 'clsx'
import { Label } from '@/components/ui/label'

export const Input = ({
  register,
  name,
  required,
  errors,
  label,
  showLabel = false,
  placeholder,
  description,
  type,
  outerClassName = 'w-full col-span-1',
  className = 'p-3 rounded-lg border border-gray-900',
}: {
  register: UseFormRegister<FieldValues>
  name: string
  required: boolean
  errors: FieldErrors<FieldValues>
  label: string
  outerClassName?: string
  showLabel?: boolean
  placeholder?: string
  description?: string
  type: string
  width?: string
  className?: string
}) => (
  <div className={clsx(`flex flex-col gap-1.5`, outerClassName)}>
    {showLabel && (
      <Label className="text-gray-700" htmlFor={name}>
        {label}
        {required && <span className="ml-[1px] text-yellow-600">*</span>}
      </Label>
    )}
    <input
      className={clsx(className)}
      {...register(name, {
        required,
      })}
      placeholder={placeholder || ``}
      type={type}
      aria-invalid={errors[name] ? 'true' : 'false'}
    />
    {description && (
      <p
        className={`text-xs leading-none text-muted-foreground ${errors[name] ? 'text-red-700' : ''}`}
      >
        {errors[name]
          ? `${errors[name]?.message?.toString()}`
          : `${description}`}
      </p>
    )}
  </div>
)
