import React from 'react'
import type { ImageType } from '../../templates/Generic'
import { GatedContentForm } from './GatedContentForm'
import { WhitePaper } from './Whitepaper'

export interface GatedContentProps {
  formid: string
  whitepaperTitle: string
  whitepaperContent?: {
    id: string
    content: string
  }[]
  gatedContentUrl: string
  image: ImageType
  whitepaper: boolean
}

export const GatedContentInterstitial = ({
  data,
}: {
  data: GatedContentProps
}) => {
  return (
    <div className="mx-auto my-auto flex h-full max-w-[960px] flex-col items-center justify-center gap-4 md:flex-row min-[1100px]:gap-8">
      <WhitePaper data={data} />
      <GatedContentForm data={data} />
    </div>
  )
}
