import React from 'react'
import { ImageProps, ImageType } from '../../templates/Generic'
import { Spacing } from '../hooks'
import { TextComponentProps } from '../TextComponent'
import { Steps } from './Steps'
import { SlidingSteps } from './SlidingSteps'

export interface StepsProps {
  title?: string
  slidingStyle?: false
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  stepBackground: ImageType
  stepList: {
    content: string
    contentBlurb: {
      id: string
      content: string
    }[]
    image?: ImageProps[]
    linkto?: string
    title: string
    stepIcon?: ImageType
  }[]
}

export const StepsComponent = ({ data }: { data: StepsProps }) => {
  if (data.slidingStyle === (false || null)) {
    return <Steps data={data} />
  } else {
    return <SlidingSteps data={data} />
  }
}
