import React from 'react'
import { ImageType } from '../../templates/Generic'
import { type ButtonProps, Spacing } from '..'
import { CTABar } from './CTABar'
import { CTABox } from './CTABox'

export interface CTABarComponentProps {
  formId?: string
  anchorTag: string
  label?: string
  content?: {
    id: string
    content: string
  }[]
  button: ButtonProps[]
  title: string
  subtext?: string
  darkMode: boolean
  boxStyle?: boolean
  gatedContent?: boolean
  successMessage?: string
  image?: ImageType
  spacing: Spacing[]
}

export const CTAComponent = ({ data }: { data: CTABarComponentProps }) => {
  return data.boxStyle ? <CTABox data={data} /> : <CTABar data={data} />
}
