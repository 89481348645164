'use client'

import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { Button, type ButtonProps, TextComponentProps } from '..'
import { Markdown } from './TableOfContentsMarkdown'
import { Spacing, TextSizeType, useSpacing, useTextSize } from '../hooks'
import { ImageType } from '@/templates/Generic'
import { motion } from 'motion/react'

export interface TableOfContentsProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  contents: {
    title: string
    titleImageIcon: ImageType
    content: string
    contentBlurb: {
      id: string
      content: string
    }[]
    cardImage?: ImageType
    text: TextSizeType
    button: ButtonProps[]
  }[]
}

const buildTracker = (contents: TableOfContentsProps['contents']) => {
  const [activeSection, setActiveSection] = useState<string | null>(null)

  useEffect(() => {
    // Keep track of all sections that are currently in view
    const visibleSections = new Set<string>()

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const id = entry.target.id

          // If section is entering the viewport
          if (entry.isIntersecting) {
            visibleSections.add(id)
          } else {
            // If section is leaving the viewport
            visibleSections.delete(id)
          }

          // Mark a section as active when its top is above 0.7 in the viewport
          // This means the section has started scrolling up but is still partially visible
          const viewportHeight = window.innerHeight
          const topPositionRatio = entry.boundingClientRect.top / viewportHeight

          if (
            entry.isIntersecting &&
            topPositionRatio <= 0.9 &&
            topPositionRatio >= 0
          ) {
            // This section's top is above the 0.7 threshold but still in viewport
            setActiveSection(id)
          }

          // If no sections are visible or we're at the top of the page
          // select the first visible section or the first section
          if (visibleSections.size === 0 || window.scrollY < 100) {
            const firstSection = contents[0]?.title
            if (firstSection) setActiveSection(firstSection)
          } else if (visibleSections.size > 0 && !activeSection) {
            // If we have visible sections but no active section, select the first visible one
            setActiveSection(Array.from(visibleSections)[0])
          }
        })
      },
      {
        root: null, // Use the viewport
        rootMargin: '0px', // No margin
        threshold: [0, 1], // Multiple thresholds for better tracking
      },
    )

    // Observe all content sections
    contents.forEach(data => {
      const element = document.getElementById(data.title)
      if (element) observer.observe(element)
    })

    return () => {
      // Cleanup
      contents.forEach(data => {
        const element = document.getElementById(data.title)
        if (element) observer.unobserve(element)
      })
    }
  }, [contents, activeSection])

  return (
    <div className="top-4 mb-auto flex flex-row flex-wrap gap-x-2 gap-y-3 md:sticky md:min-w-[180px] md:flex-col md:gap-2">
      {contents.map(data => (
        <a
          aria-selected={activeSection === data.title}
          key={data.title}
          href={`#${data.title}`}
          onClick={() => setActiveSection(data.title)}
          className="rounded-md px-3 py-2 text-sm font-medium text-gray-600 no-underline aria-selected:border aria-selected:border-gray-300 aria-selected:bg-gray-100 aria-selected:text-gray-900"
        >
          <h4>{data.title}</h4>
        </a>
      ))}
    </div>
  )
}

const buildContentCards = (contents: TableOfContentsProps['contents']) =>
  contents.map(data => (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true, margin: '-100px' }}
      className="flex-col gap-6 rounded-xl border border-gray-300 bg-white p-6 shadow-sm"
      id={data.title}
    >
      {/* Title Row  */}
      <div className="mb-6 flex flex-row gap-2">
        <img
          className="h-10 w-10"
          src={data.titleImageIcon?.url || ''}
          alt={
            data.titleImageIcon?.alt ||
            data.titleImageIcon?.filename ||
            `${data.title}`
          }
        />
        <h3 className="my-auto font-MaisonNeueExtended text-lg font-semibold text-gray-800">
          {data.title}
        </h3>
      </div>
      {/* End Title Row  */}

      {/* Handle Image Cases */}
      {data.cardImage ? (
        <div className="flex flex-col lg:grid lg:grid-cols-[60%_40%]">
          <div className="md:my-auto">
            <Markdown
              className="text-base lg:text-lg"
              markdown={data.content}
            />
            {data.contentBlurb.map(blurb => (
              <Markdown key={blurb.id} markdown={blurb.content} />
            ))}
          </div>
          <img
            className="md:my-auto"
            src={data.cardImage?.url || ''}
            alt={data.cardImage?.alt || data.cardImage?.filename || ''}
          />
        </div>
      ) : (
        <>
          <Markdown className="text-base lg:text-lg" markdown={data.content} />
          {data.contentBlurb.map(blurb => (
            <Markdown key={blurb.id} markdown={blurb.content} />
          ))}
        </>
      )}
    </motion.div>
  ))

export const TableOfContentsComponent = ({
  data,
}: {
  data: TableOfContentsProps
}) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div
      className={clsx(
        'flex h-min max-w-page flex-col gap-4 px-4 py-8 md:mx-auto md:flex-row md:gap-8 md:px-16 lg:px-20 xl:px-24',
        spacing,
      )}
    >
      {buildTracker(data.contents)}
      <div className="flex flex-col gap-6">
        {buildContentCards(data.contents)}
      </div>
    </div>
  )
}
