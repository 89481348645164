import { getCookie } from '@/utils'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../Button'
import { Message, Input } from '../FormComponent/Components'
import { Card } from '../ui'
import { GatedContentProps } from './GatedContentComponent'
import React from 'react'

export const GatedContentForm = ({ data }: { data: GatedContentProps }) => {
  const [status, setStatus] = useState('')
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const formId = data.formid

  const downloadFile = async () => {
    try {
      const response = await fetch(data.gatedContentUrl)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = data.gatedContentUrl.split('/').pop() || 'download'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const onSubmit = async (data: any) => {
    setStatus('pending')
    /* const ipRes = await fetch('https://jsonip.com/')
       const { ip: ipAddress } = await ipRes.json() */
    const hutk = getCookie('hubspotutk')
    const loc = location?.href

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/48233040/${formId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: Object.keys(data).map(key => ({
            name: key,
            value: data[key],
          })),
          context: {
            hutk,
            pageUri: loc,
            // Return page name as the last index of the url
            pageName: loc,
            /* ipAddress, */
          },
        }),
      },
    )
    if (res.ok && res.status < 400) {
      await downloadFile()
      setStatus('completed')
      reset()
    } else setStatus('failed')
  }

  const pending = status === 'pending'
  const completed = status === 'completed'
  const failed = status === 'failed'

  return (
    <Card className="z-[5] flex-col gap-8 rounded-[6px] bg-white px-6 py-7 shadow-xl md:shadow-md">
      <h3 className="mb-6 text-center text-sm font-medium text-black sm:text-xl">
        Access the whitepaper
      </h3>
      {/* Content container */}
      {completed ? (
        <div className="flex flex-col gap-4">
          <Message
            message="Thanks for submitting.  You'll download your content momentarily."
            success
          />
          <Message message="Best, ProvLabs team" success />
        </div>
      ) : (
        <div className="my-auto flex flex-col gap-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
            className="grid w-full grid-cols-2 items-center gap-[12px] lg:gap-x-[18px] lg:gap-y-[22px]"
          >
            <Input
              register={register}
              name="0-1/firstname"
              type="text"
              required
              label="First Name"
              className="flex h-[38px] w-full rounded-md border border-input bg-transparent bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 sm:text-base"
              placeholder="First Name"
              errors={errors}
            />
            <Input
              register={register}
              name="0-1/lastname"
              type="text"
              required
              label="Last Name"
              className="flex h-[38px] w-full rounded-md border border-input bg-transparent bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 sm:text-base"
              placeholder="Last Name"
              errors={errors}
            />
            <Input
              register={register}
              name="0-1/email"
              type="email"
              required
              label="Email"
              outerClassName="col-span-2 w-full"
              className="flex h-[38px] w-full rounded-md border border-input bg-transparent bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 sm:text-base"
              placeholder="Email"
              errors={errors}
            />
            <Input
              register={register}
              name="0-2/name"
              type="text"
              required
              label="Company Name"
              outerClassName="col-span-2 w-full"
              className="flex h-[38px] w-full rounded-md border border-input bg-transparent bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 sm:text-base"
              placeholder="Company Name"
              errors={errors}
            />

            <Button
              btnType="light"
              size="small"
              type="submit"
              disabled={pending}
              className="col-span-2 w-full"
            >
              Download
            </Button>
          </form>
        </div>
      )}
      {failed && <Message failure />}
    </Card>
  )
}
