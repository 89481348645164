import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form'
import clsx from 'clsx'
export const Checkbox = ({
  register,
  name,
  required,
  errors,
  className,
}: {
  register: UseFormRegister<FieldValues>
  name: string
  required: boolean
  errors: FieldErrors<FieldValues>
  className?: string
}) => (
  <input
    className={clsx('mr-2 h-4 w-4 accent-blue-700', className)}
    {...register(name, {
      required,
    })}
    type="checkbox"
    aria-invalid={errors[name] ? 'true' : 'false'}
  />
)
