import clsx from 'clsx'
import React from 'react'
import {
  Button,
  type ButtonProps,
  Spacing,
  TextComponent,
  TextComponentProps,
  useMediaQuery,
  useSpacing,
  TextSizeType,
  useTextSize,
  Markdown,
} from '..'
import { ImageProps } from '../../templates/Generic'
import { breakpoints } from '../consts'

export interface FeatureProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  card: {
    content: string
    image: ImageProps[]
    title: string
    text: TextSizeType
    button: ButtonProps[]
  }[]
}

const buildFeatureCards = ({
  data,
  mobile,
}: {
  data: FeatureProps['card']
  mobile?: boolean
}) => (
  <div
    className={`relative grid ${
      mobile ? 'grid-cols-1' : 'grid-cols-2'
    } gap-8 px-4 py-2 xl:px-16`}
  >
    {data.map(card => {
      const textSize = useTextSize({ textSize: card.text })
      return (
        <div
          key={card.title}
          className="grid grid-cols-1 items-start rounded-3xl px-8 shadow-standard"
        >
          <img
            className="pt-12 align-middle"
            src={card.image[0].responsiveImage.url as string}
            alt={
              card.image[0].responsiveImage.alt ||
              card.image[0].responsiveImage.filename ||
              `${card.title} icon`
            }
          />
          <div className="py-4 text-xl font-semibold text-black">
            {card.title}
          </div>
          <div className={clsx(`text-xl text-gray-700 ${textSize}`)}>
            <Markdown markdown={card.content} />
          </div>
          <div className="align-end justify-self-start py-12">
            <Button
              btnType={card.button[0].btnType}
              size={card.button[0].size}
              linkTo={card.button[0].linkTo}
            >
              {card.button[0].label}
            </Button>
          </div>
        </div>
      )
    })}
  </div>
)

export const Features = ({ data }: { data: FeatureProps }) => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('xl'))
  const spacing = useSpacing(data.spacing?.[0] as Spacing)

  return (
    <div className={`max-w-page md:mx-auto ${spacing}`}>
      <div className=" px-4 md:px-16 lg:px-20 xl:px-24">
        <TextComponent data={data.titleSection} />
      </div>
      {buildFeatureCards({ data: data.card, mobile: renderMobile })}
    </div>
  )
}
