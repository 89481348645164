import * as React from 'react'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../components/ui/carousel'
import {
  Button,
  Spacing,
  TextComponentProps,
  ButtonProps,
  TextSizeType,
  NewFeaturesProps,
  useSpacing,
  useMediaQuery,
  breakpoints,
  Markdown,
} from '../'
import { ImageType } from '../../templates/Generic'
import clsx from 'clsx'

export interface UseCaseCarouselProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  cardList: {
    title: string
    content: string
    contentBlurb: {
      id: string
      content: string
    }[]
    cardImage?: ImageType
    text: TextSizeType
    button: ButtonProps[]
  }[]
}

const buildUseCaseCards = (cardList: NewFeaturesProps['cardList']) =>
  cardList.map(card => (
    <CarouselItem
      key={card.cardImage?.url || ''}
      className="basis-[100%] sm:basis-[48%] lg:basis-1/2 xl:basis-1/3"
    >
      <div className="flex h-full flex-col justify-between gap-12 rounded-3xl bg-white px-10 py-10 shadow-standard lg:px-12 lg:py-12 xl:ml-8">
        <div className="flex flex-col gap-12">
          <img
            className="h-10 object-contain"
            src={card.cardImage?.url || ''}
            alt={
              card.cardImage?.alt ||
              card.cardImage?.filename ||
              `${card.title} icon`
            }
          />
          <div className="text-lg text-gray-600 lg:text-xl">
            {card.contentBlurb.map(blurb => (
              <Markdown key={blurb.id} markdown={blurb.content} />
            ))}
          </div>
        </div>
        {card.button[0] && (
          <Button
            className="w-full"
            children="Learn More"
            btnType="primary"
            size="medium"
            linkTo={card.button[0].linkTo}
          />
        )}
      </div>
    </CarouselItem>
  ))

export const UseCaseCarousel = ({ data }: { data: NewFeaturesProps }) => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('md'))
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div
      className={clsx(
        'mb-0 mt-0 max-w-page items-center justify-center gap-0 overflow-hidden px-12 py-16 pt-0 md:mx-auto md:mb-0 md:mt-0 md:pb-24 md:pt-0 lg:px-24',
        spacing,
      )}
    >
      <Carousel
        opts={{
          align: 'start',
        }}
      >
        <CarouselContent className="flex w-auto flex-row gap-8 xl:-ml-8 xl:gap-0">
          {buildUseCaseCards(data.cardList)}
        </CarouselContent>
        <CarouselPrevious className="" />
        <CarouselNext className="" />
      </Carousel>
    </div>
  )
}
