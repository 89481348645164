import React, { useEffect, useState } from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import clsx from 'clsx'
import qs from 'query-string'
import { Grid } from '../Grid'
import { Spacing, useSpacing } from '../hooks'
import { PaginationClicker } from '../Quotations/Components/PaginationClicker'
import { BlogPostCard, BlogPostCardProps } from './BlogPostCard'

export type BlogListProps = {
  data: Queries.DatoCmsBlogPostList
  location: PageProps['location']
}

export const getArticle = (post: BlogPostCardProps['article']) => ({
  ...post,
  to:
    post.blogOrNews === 'news'
      ? post.externalLink
      : `/${post.blogType?.name || 'learn'}/posts/${post.slug}`,
})

export const BlogList = ({ data, location }: BlogListProps) => {
  const hasFeatures = !!data?.featuredPosts?.length
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const [postsByType, setPostsByType] = useState<Queries.DatoCmsBlogPost[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState(10)
  const pageSize = data?.postsPerPage || 10

  const {
    allDatoCmsBlogPost: { nodes: blogPosts },
  } = useStaticQuery(graphql`
    {
      allDatoCmsBlogPost(sort: { date: DESC }) {
        nodes {
          ...datoBlogPost
        }
      }
    }
  `)

  useEffect(() => {
    const { tag } = qs.parse(location.search)
    setPostsByType(
      blogPosts.filter((post: Queries.DatoCmsBlogPost) => {
        // if a blog tag nav item is active
        if (tag && tag !== 'all')
          return (
            post.tags?.[0]?.name?.toLowerCase() ===
              (tag as string)?.toLowerCase() &&
            (!data.blogType?.name || data.blogType.name === post.blogType?.name)
          )

        return (
          !data.blogType?.name || data.blogType.name === post.blogType?.name
        )
      }),
    )
  }, [blogPosts, location])

  useEffect(() => {
    setPages(Math.ceil(postsByType.length / pageSize))
  }, [postsByType])

  useEffect(() => {
    // reset page scroll when the pagination changes
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [currentPage])

  return (
    <div
      key={data.id}
      className={clsx(
        'max-w-page px-4 md:mx-auto md:px-16 lg:px-20 xl:px-24',
        spacing,
      )}
    >
      <Grid {...(data.layout?.[0] as any)}>
        {hasFeatures && (
          <Grid {...(data.layout?.[0] as any)} className="col-span-full my-16">
            {data.featuredPosts.map(post => (
              <BlogPostCard
                key={post?.id}
                article={getArticle(post as any)}
                widthFull
                showImage
              />
            ))}
          </Grid>
        )}

        {postsByType
          ?.slice(pageSize * (currentPage - 1), pageSize * currentPage)
          .map((post: Queries.DatoCmsBlogPost) => (
            <BlogPostCard
              key={post.id}
              article={getArticle(post as any)}
              showImage={data.showImages || false}
            />
          ))}

        {pages > 1 && data.showPagination && (
          <div className="col-span-full">
            <PaginationClicker
              currentNumber={currentPage}
              setCurrentNumber={setCurrentPage}
              totalItems={pages}
            />
          </div>
        )}
      </Grid>
    </div>
  )
}
