import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import { Markdown } from '..'

const headingVariants = cva('font-bold tracking-tight', {
  variants: {
    size: {
      h1: 'text-4xl md:text-5xl lg:text-6xl',
      h2: 'text-3xl md:text-4xl lg:text-5xl',
      h3: 'text-2xl md:text-3xl',
      h4: 'text-xl md:text-2xl',
      h5: 'text-lg md:text-xl',
      h6: 'text-base md:text-lg',
    },
    variant: {
      default: 'text-gray-900',
      muted: 'text-gray-700',
      accent: 'text-blue-700',
      light: 'text-gray-100',
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold',
    },
  },
  defaultVariants: {
    size: 'h2',
    variant: 'default',
    align: 'left',
    weight: 'bold',
  },
})

const textVariants = cva('', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      base: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
    },
    variant: {
      default: 'text-gray-900',
      muted: 'text-gray-600',
      accent: 'text-blue-700',
      light: 'text-gray-100',
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    },
    leading: {
      none: 'leading-none',
      tight: 'leading-tight',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
      loose: 'leading-loose',
    },
    width: {
      auto: 'max-w-none',
      prose: 'max-w-prose',
      xs: 'max-w-xs',
      sm: 'max-w-sm',
      md: 'max-w-md',
      lg: 'max-w-lg',
      xl: 'max-w-xl',
      '2xl': 'max-w-2xl',
      '3xl': 'max-w-3xl',
      '4xl': 'max-w-4xl',
      '5xl': 'max-w-5xl',
      '6xl': 'max-w-6xl',
      '7xl': 'max-w-7xl',
      full: 'max-w-full',
    },
  },
  defaultVariants: {
    size: 'base',
    variant: 'default',
    align: 'left',
    weight: 'normal',
    leading: 'normal',
    width: 'auto',
  },
})

export interface HeadingProps extends VariantProps<typeof headingVariants> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
  children: React.ReactNode
  id?: string
}

export const Heading = ({
  as: Tag = 'h2',
  size,
  variant,
  align,
  weight,
  className,
  children,
  ...props
}: HeadingProps) => {
  return (
    <Tag
      className={cn(
        headingVariants({ size, variant, align, weight, className }),
      )}
      {...props}
    >
      {children}
    </Tag>
  )
}

export interface TextProps extends VariantProps<typeof textVariants> {
  as?: 'p' | 'span' | 'div'
  className?: string
  children: React.ReactNode
  markdown?: boolean
  id?: string
}

export const Text = ({
  as: Component = 'p',
  size,
  variant,
  align,
  weight,
  leading,
  width,
  className,
  children,
  markdown = false,
  ...props
}: TextProps) => {
  const textClasses = cn(
    textVariants({ size, variant, align, weight, leading, width, className }),
  )

  if (markdown && typeof children === 'string') {
    return (
      <div className={textClasses} {...props}>
        <Markdown markdown={children} />
      </div>
    )
  }

  return (
    <Component className={textClasses} {...props}>
      {children}
    </Component>
  )
}

export const Label = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={cn('text-sm font-medium text-gray-700', className)}
    {...props}
  >
    {children}
  </span>
)
