import clsx from 'clsx'
import { Button } from '../Button'
import { breakpoints } from '../consts'
import { useTextSize, useMediaQuery, useSpacing, Spacing } from '../hooks'
import { Markdown } from '../Markdown'
import { TextComponent } from '../TextComponent'
import { NewFeaturesProps } from './NewFeaturesComponent'
import React from 'react'

const buildFourColumnCards = (cardList: NewFeaturesProps['cardList']) =>
  cardList.map(card => (
    <div key={card.title} className="flex flex-col gap-1 md:gap-2">
      <h3 className="text-[20px] font-normal text-gray-800">{card.title}</h3>
      <div
        className={clsx(
          'text-xs font-normal text-gray-600 md:text-base',
          useTextSize({ textSize: card.text }),
          {
            'mb-8': card.button.length > 0,
          },
        )}
      >
        <Markdown markdown={card.content} />
        {card.contentBlurb.map(blurb => (
          <Markdown key={blurb.id} markdown={blurb.content} />
        ))}
      </div>
      {card.button.map((button, index) => (
        <Button
          key={button.label}
          linkTo={button.linkTo}
          btnType={button.btnType}
          size={button.size}
          className={`${index > 0 && 'ml-4'}`}
        >
          {button.label}
        </Button>
      ))}
    </div>
  ))

export const NewFeaturesFourColumn = ({ data }: { data: NewFeaturesProps }) => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('md'))
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div
      className={clsx(
        'flex h-min max-w-page flex-col px-4 py-8 md:mx-auto md:px-16 lg:px-20 xl:px-24',
        spacing,
      )}
    >
      <div>
        <TextComponent data={data.titleSection} />
        {/* Title Row  */}
        <div className="mb-6 flex flex-row gap-2">
          <img
            className="h-8 w-8"
            src={data.image?.url || ''}
            alt={
              data.image?.alt ||
              data.image?.filename ||
              `${data.titleSection[0].title}`
            }
          />
          <h3 className="my-auto font-MaisonNeueExtended text-lg font-semibold text-gray-800">
            {data.title}
          </h3>
        </div>
        {/* End Title Row  */}
        <div className="flex flex-col gap-3 sm:grid sm:grid-cols-2 sm:gap-10 xl:grid-cols-4 xl:gap-8">
          {buildFourColumnCards(data.cardList)}
        </div>
      </div>
    </div>
  )
}
