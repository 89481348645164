import clsx from 'clsx'
import { format } from 'date-fns'
import React from 'react'
import { Button } from '..'
import { ImageType } from '@/templates/Generic'

export interface BlogPostCardProps {
  article: {
    blogOrNews?: string
    blogAuthor?: {
      name?: string
      image?: {
        url?: string
        alt?: string
        filename?: string
      }
      guestContributor?: boolean
    }
    blogType?: {
      name?: string
    }
    newsAuthor?: {
      name?: string
      image?: {
        url?: string
        alt?: string
        filename?: string
      }
      guestContributor?: boolean
    }
    date?: string
    tags?: Array<{
      name?: string
    }>
    title?: string
    briefDescription?: string
    image?: ImageType
    externalLink?: string
    slug?: string
    to?: string
  }
  showImage?: boolean
  widthFull?: boolean
}

export const BlogPostCard = ({
  article,
  showImage = false,
  widthFull = false,
}: BlogPostCardProps) => {
  let dt, dtDateOnly

  if (article.date) {
    dt = new Date(article.date)
    dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  }

  return (
    <div
      className={clsx(
        'flex flex-col overflow-hidden rounded-2xl bg-white shadow-md',
        {
          'col-span-full lg:flex-row': widthFull,
        },
      )}
    >
      {showImage && article.image ? (
        <div className="w-full">
          <img
            className={clsx('flex items-center bg-gray-200', {
              'h-full w-auto object-cover md:row-span-2': widthFull,
            })}
            src={article.image?.url || ''}
            alt={article.image?.alt || article.image?.filename || ''}
          />
        </div>
      ) : showImage ? (
        // Fallback in case the post doesn't have an image but showImages is true
        <div
          className={clsx('flex h-56 items-center bg-gray-200', {
            'md:row-span-2 md:h-auto md:rounded-l-3xl': widthFull,
          })}
        />
      ) : null}
      <div
        className={clsx('col-span-2 flex h-full flex-col px-8', {
          'pt-8': showImage,
          'pt-12': !showImage,
          'lg:w-[33%] lg:min-w-[415px]': widthFull,
        })}
      >
        <header className="flex justify-between">
          <div className="flex items-center gap-2">
            {(article?.blogAuthor?.image || article?.newsAuthor?.image) && (
              <div className="h-10 w-10">
                <img
                  className="w-full rounded-full"
                  src={
                    article.blogAuthor?.image?.url ||
                    article.newsAuthor?.image?.url ||
                    ''
                  }
                  alt={
                    article.blogAuthor?.image?.alt ||
                    article.blogAuthor?.image?.filename ||
                    article.blogAuthor?.name ||
                    article.newsAuthor?.image?.alt ||
                    article.newsAuthor?.image?.filename ||
                    article.newsAuthor?.name ||
                    ''
                  }
                />
              </div>
            )}
            <div
              className={clsx('flex gap-1', {
                'flex-col': !widthFull,
                'flex-row items-center': widthFull,
              })}
            >
              <span
                className={clsx('text-sm text-grey-900', {
                  [`border-r-solid border-r border-r-gray-400 pr-1 leading-none`]:
                    widthFull,
                })}
              >
                {article?.blogAuthor?.name || article?.newsAuthor?.name}
              </span>
              <span className="text-sm text-grey-500">
                {dtDateOnly && format(dtDateOnly, 'MM/dd/yyyy')}
              </span>
            </div>
          </div>
          {(article?.blogAuthor?.guestContributor ||
            article?.newsAuthor?.guestContributor) && (
            <div className="h-10 w-32 bg-community-contributor bg-right bg-no-repeat" />
          )}
        </header>
        <section className="my-8 grid grid-cols-1 gap-2">
          <span
            className={clsx('text-sm font-semibold text-blue-700', {
              hidden: article.title === 'ProvConnect MVP Demonstration',
            })}
          >
            {article?.tags?.[0]?.name}
          </span>
          <h3 className="font-MaisonNeueExtended text-xl font-semibold text-black">
            {article?.title}
          </h3>
          <p className="text-black/60">{article?.briefDescription}</p>
        </section>
        <footer className="mt-auto pb-12">
          <Button className="w-full" linkTo={article?.to}>
            {article.title === 'ProvConnect MVP Demonstration'
              ? 'Watch'
              : 'Read More'}
          </Button>
        </footer>
      </div>
    </div>
  )
}
