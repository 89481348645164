import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'

const containerVariants = cva('mx-auto px-4 md:px-6 lg:px-8', {
  variants: {
    size: {
      xs: 'max-w-screen-xs',
      sm: 'max-w-screen-sm',
      md: 'max-w-screen-md',
      lg: 'max-w-screen-lg',
      xl: 'max-w-screen-xl',
      '2xl': 'max-w-screen-2xl',
      full: 'max-w-full',
      page: 'max-w-page', // Custom max width defined in your project
    },
    padding: {
      none: 'p-0',
      sm: 'py-4',
      md: 'py-8',
      lg: 'py-12',
      xl: 'py-16',
      '2xl': 'py-24',
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    },
  },
  defaultVariants: {
    size: 'xl',
    padding: 'md',
    align: 'left',
  },
})

export interface ContainerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof containerVariants> {
  as?: 'div' | 'section' | 'article' | 'main'
  children: React.ReactNode
}

export const Container = ({
  as: Component = 'div',
  size,
  padding,
  align,
  className,
  children,
  ...props
}: ContainerProps) => {
  return (
    <Component
      className={cn(containerVariants({ size, padding, align, className }))}
      {...props}
    >
      {children}
    </Component>
  )
}

// Specialized container for content sections
export const ContentContainer = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('mx-auto max-w-prose px-4 md:px-6 lg:px-8', className)}
      {...props}
    >
      {children}
    </div>
  )
}

// Grid container with responsive columns
export interface GridContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  columns?: {
    sm?: 1 | 2 | 3 | 4 | 5 | 6
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  }
  gap?: 'none' | 'sm' | 'md' | 'lg' | 'xl'
  children: React.ReactNode
}

export const GridContainer = ({
  columns = { sm: 1, md: 2, lg: 3 },
  gap = 'md',
  className,
  children,
  ...props
}: GridContainerProps) => {
  return (
    <div
      className={cn(
        'grid',
        {
          'grid-cols-1': true,
          [`sm:grid-cols-${columns.sm}`]: columns.sm,
          [`md:grid-cols-${columns.md}`]: columns.md,
          [`lg:grid-cols-${columns.lg}`]: columns.lg,
          'gap-0': gap === 'none',
          'gap-2': gap === 'sm',
          'gap-4': gap === 'md',
          'gap-8': gap === 'lg',
          'gap-12': gap === 'xl',
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
